
import { Tabs, Empty } from 'antd';
import ReactECharts from 'echarts-for-react';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { observer } from 'mobx-react';
import React from 'react';
import { api } from '../../../utils';
import { baseOptions } from './baseOptions';
import styles from './index.less';
import type { ProductList, StatisticsData } from './interface';

const { TabPane } = Tabs;

const tabsData = [
  {
    name: '选款TOP5',
    key: 'SELECTED',
  },
  {
    name: '依链销量TOP5',
    key: 'POS_SALE',
  },
  {
    name: '依链选款TOP5',
    key: 'MALL_SALE',
  },
];

@observer
export class TopStatics extends React.Component<{ isSCL?: boolean; }> {
  public state = {
    tab: 'SELECTED',
    productList: [], // 款式id列表
    productId: null, // 款式id
    xAxisData: [],
    yAxisData: [],
  };

  componentDidMount(): void {
    this.findTopRank(this.state.tab);
  }

  public onTabChange = (tab: string): void => {
    this.setState({ tab });
    this.findTopRank(tab);
  };

  public findTopRank = async(tab: string): Promise<void> => {
    const res: BaseData<ProductList[]> = await request({ url: `${api.findTopRank}?queryType=${tab}` });
    const productId = res.data.length > 0 ? res.data[0].id : null;
    this.setState({
      productList: res.data,
      productId,
    });
    this.queryTopSale(productId);
  };

  public handleChangeProductId = (activeKey: string): void => {
    this.setState({ productId: activeKey });
    this.queryTopSale(Number(activeKey));
  };

  public queryTopSale = async(productId: number): Promise<void> => {
    if (productId) {
      const res: BaseData<StatisticsData[]> = await request({
        url: api.findTopFrom,
        method: 'POST',
        data: {
          queryType: this.state.tab,
          id: productId,
        },
      });
      const xAxisData = res.data.map((item) => item.date);
      const yAxisData = res.data.map((item) => item.num);
      this.setState({
        xAxisData,
        yAxisData,
      });
    }
  };

  render(): JSX.Element {
    const { tab, productList, productId, xAxisData, yAxisData } = this.state;
    const tabList = this.props.isSCL ? [
      tabsData[1],
      tabsData[2],
    ] : tabsData;
    return (
      <div className={styles.topStatics}>
        <div className={styles.tabWrapper}>
          {tabList.map((item) => (
            <div
              className={tab === item.key ? `${styles.tabItem} ${styles.activeTabItem}` : styles.tabItem}
              key={item.key}
              onClick={() => this.onTabChange(item.key)}
            >
              <span>
                {item.name}
              </span>
              <span className={tab === item.key ? styles.active : undefined}/>
            </div>
          ))}
        </div>
        {productList.length > 0 ? (
          <div className={styles.chartsPart}>
            <Tabs
              activeKey={String(productId)}
              className={styles.tabs}
              onChange={this.handleChangeProductId}
              tabPosition="left"
            >
              {productList.map((item) => (
                <TabPane
                  key={item.id}
                  tab={item.goodsOrProductNo}
                />
              ))}
            </Tabs>
            <ReactECharts
              className={styles.eCharts}
              lazyUpdate
              notMerge
              option={{
                ...baseOptions,
                xAxis: {
                  ...baseOptions.xAxis,
                  data: xAxisData,
                },
                series: {
                  ...baseOptions.series,
                  data: yAxisData,
                  name: tab === 'SELECTED' ? '选款' : tab === 'POS_SALE' ? '开单销量' : '销售销量',
                },
              }}
              style={{ height: 200 }}
              theme="theme_name"
            />
          </div>
        ) : (
          <div className={styles.empty}>
            <Empty
              description="暂无数据哦！"
              image={`${api.oss}/images/empty.png`}
            />
          </div>
        )}
      </div>
    );
  }
}

