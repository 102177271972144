import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type { Product } from './interface';
import { Popover } from 'antd';
import { SystemUpdate } from './systemUpdate';
import { api } from '../../../utils';

interface Props {
  platformType: number;
}

@observer
export class RelatedProduct extends React.Component<Props> {
  public renderProductItem = (item: Product): JSX.Element => {
    return (
      <div
        className={styles.item}
        key={item.id}
      >
        <img
          className={styles.img}
          src={`${api.oss}/images/${item.img}`}
        />
        <div className={styles.text}>
          <span>
            {item.name}
          </span>
        </div>
      </div>
    );
  };

  render(): JSX.Element {
    const products: Product[] = [
      {
        img: 'baokuanApp.png',
        id: 'baokuanApp',
        name: '衫海精',
        content: (
          <img
            className={styles.contentImg}
            src={`${api.oss}/images/baokuanAppCode.png`}
          />
        ),
      },
      {
        img: 'baokuanMiniPrograme.png',
        id: 'baokuanMiniPrograme',
        name: '衫海精灵',
        content: (
          <img
            className={styles.contentImg}
            src={`${api.oss}/images/pushModelAppCode.png`}
          />
        ),
      },
      {
        img: 'youyouNumber.png',
        id: 'youyouNumber',
        name: '优优数字',
        content: (
          <img
            className={styles.contentImg}
            src={`${api.oss}/images/digitalCode.jpg`}
          />
        ),
      },
      {
        img: 'offcialAccounts.png',
        id: 'offcialAccounts',
        name: '公众号',
        content: (
          <img
            className={styles.contentImg}
            src={`${api.oss}/images/offcialAccounts.jpg`}
          />
        ),
      },
    ];

    const reference = [
      {
        name: 'Lodop打印组件',

        url: `${api.oss}/others/CLodop_Setup_for_Win64NT_4.158EN.zip`,
        img: 'lodop.png',
        download: 'Lodop打印组件',
      },
    ];

    return (
      <div className={styles.relatedProduct}>
        <div className={styles.productWrapper}>
          <div className={styles.header}>
            <div className={styles.title}>
              相关产品
            </div>
          </div>
          <div className={styles.content}>
            {products.map((item) => (
              <Popover
                content={item.content}
                key={item.id}
              >
                {this.renderProductItem(item)}
              </Popover>
            ))}
          </div>
          <div className={styles.divideLine}/>
          <div className={styles.downloadLinkContent}>
            {reference.map((item) => (
              <a
                className={styles.item}
                download={item.download}
                href={item.url}
                key={item.name}
                rel="noreferrer"
                target={!item.download ? '_blank' : undefined}
              >
                <img
                  className={styles.img}
                  src={`${api.oss}/images/${item.img}`}
                />
                <span>
                  {item.name}
                </span>
              </a>
            ))}
          </div>
        </div>
        <SystemUpdate platformType={this.props.platformType}/>
      </div>
    );
  }
}
