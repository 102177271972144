import { message } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { observable, action } from 'mobx';
import { api } from '../../../utils/api';
import type { MenuGroup, Menu, CommonInfo } from './interface';

export default class ShortCutStore {
  constructor(props) {
    this.homePageType = props && props.homePageType ? props.homePageType : 0;
  }

  @observable public menuGroup: MenuGroup[] = []; // 未添加的菜单项

  @observable public isShowEdit = false; // 编辑弹窗显隐

  @observable public menu: Menu[] = []; // 快捷入口

  @observable public currentEditMenu: Menu[] = []; // 编辑-已添加菜单

  @observable public homePageType = 0; // 首页(账户)类型，1:零售商,2:供应商

  @action public getCurrentMenu = async(): Promise<void> => {
    const res: BaseData<CommonInfo> = await request({ url: `${api.queryCommonInfo}?homePageType=${this.homePageType}` });
    this.menu = res.data.shortcut;
  };

  // 点击菜单跳转
  @action public handleShortCutOpenTab = (item: Menu): void => {
    // 销售开单等菜单要携带仓库ID
    const warehouseMenu = sessionStorage.getItem('warehouseMenu');
    const warehouseList = warehouseMenu ? JSON.parse(warehouseMenu) : [];
    const currentWareHouse = sessionStorage.getItem('currentWareHouse');
    const url = warehouseList.includes(Number(item.id)) ? `${item.url}?shopAndWarehouseId=${currentWareHouse}` : item.url;
    window.top.egenie.openTab(url, item.id, item.name, item.icon);
  };

  // 快捷入口切换弹窗显隐
  @action public toggleShortcutEdit = (isShow: boolean): void => {
    this.isShowEdit = isShow;
    if (isShow) {
      this.currentEditMenu = [...this.menu];
      this.queryMenuGroup();
    }
  };

  // // 快捷入口的菜单列表
  @action public queryMenuGroup = async(): Promise<void> => {
    const res: MenuGroup[] = await request({
      url: api.getAllMenus,
      method: 'POST',
      data: { homePageType: this.homePageType },
    });

    const resultData = res.map((group) => {
      const filterMenu = group.menus.filter((child) => {
        const index = this.menu.findIndex((item) => item.id === child.id);
        if (index > -1) {
          this.addParentIdToCurrentMenu(index, group.id);
        }
        return index === -1;
      });
      return {
        ...group,
        menus: filterMenu,
      };
    });
    this.menuGroup = resultData;
  };

  // 给已经选择的快捷方式添加父级ID
  @action public addParentIdToCurrentMenu = (index: number, id: number): void => {
    const data = this.currentEditMenu;
    data[index] = {
      ...data[index],
      parentId: id,
    };
    this.currentEditMenu = data;
  };

  // 快捷入口添加操作
  @action public handleShortAdd = (item?: Menu, parentId?: number, index?: number): void => {
    if (this.currentEditMenu.length === 10) {
      message.destroy();
      message.info('最多添加10个');
      return;
    }
    this.currentEditMenu = this.currentEditMenu.concat({
      ...item,
      parentId,
    });

    // 从菜单目录里去除要添加的快捷项
    const chooseMenu = this.menuGroup[index];
    const menus = chooseMenu.menus.filter((menu) => menu.id !== item.id);
    this.menuGroup[index].menus = menus;
  };

  // 快捷入口删除操作
  @action public handleShortDelete = (item?: Menu): void => {
    this.currentEditMenu = this.currentEditMenu.filter((menu) => menu.id !== item.id);

    // 从菜单目录里添加要删除的快捷项
    const idx = this.menuGroup.findIndex((group) => group.id === item.parentId);
    this.menuGroup[idx].menus = this.menuGroup[idx].menus.concat(item);
  };

  @action public handleShortCutSave = async(): Promise<void> => {
    const ids = this.currentEditMenu.map((menu) => menu.id);
    await request({
      method: 'post',
      url: api.saveShortcut,
      data: { ids: ids.join(',') },
    });
    this.menu = [...this.currentEditMenu];
    this.toggleShortcutEdit(false);
  };
}
