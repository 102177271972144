import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { api } from '../../../utils/api';
import styles from './index.less';
import type { Message, NoticeItem } from './interface';

interface Props {
  platformType: number;
  height?: number;
}

@observer
export class Notice extends React.Component<Props> {
  public state = {
    // unreadNum: 0,
    // messageInfo: '',
    notice: {
      title: '',
      createdAt: '',
      introduction: '',
      id: null,
    },
  };

  async componentDidMount(): Promise<void> {
    // const res: BaseData<Message> = await request({ url: api.getPosHomeMsg });
    // this.setState({
    //   unreadNum: res.data.num,
    //   messageInfo: res.data.content,
    // });
    const result: BaseData<NoticeItem[]> = await request({
      url: api.findNoticeRecently,
      method: 'POST',
      data: {
        noticeType: 1, // 1:公告 2:系统更新
        number: 1,
        platformType: this.props.platformType, // 1:erp 2:pos 3:零售商app 4:供应商app 5：网仓 6:scl
      },
    });
    if (result.data && result.data.length > 0) {
      this.setState({ notice: result.data[0] });
    }
  }

  public handleGoToNotice = (id?: number): void => {
    top.egenie.openTab(`/egenie-erp-home/announcement?type=1&platformType=${this.props.platformType}&id=${id ? id : ''}`, 'announcementList', '公告');
  };

  public handleGoToNotifyCenter = (): void => {
    console.log('handleGoToNotifyCenter.....');
    top.egenie.openTab('/egenie-erp-home/notificationCenter', 'notificationCenter', '通知中心');
  };

  render(): JSX.Element {
    const {
      // unreadNum,
      // messageInfo,
      notice,
    } = this.state;
    return (
      <div
        className={styles.notice}
        style={{ height: this.props.height }}
      >
        {/* <div className={styles.message}> */}
        {/*   <span className={styles.flex}> */}
        {/*     <img */}
        {/*       className={styles.icon} */}
        {/*       src={`${api.oss}/images/notificationCenter.png`} */}
        {/*     /> */}
        {/*     <span className={styles.content}> */}
        {/*       {messageInfo} */}
        {/*     </span> */}
        {/*   </span> */}
        {/*   <span */}
        {/*     className={styles.flex} */}
        {/*     onClick={this.handleGoToNotifyCenter} */}
        {/*   > */}
        {/*     {unreadNum > 0 ? ( */}
        {/*       <span className={styles.unread}> */}
        {/*         {unreadNum} */}
        {/*         条未读 */}
        {/*       </span> */}
        {/*     ) : ( */}
        {/*       <span */}
        {/*         className={styles.moreText} */}
        {/*       > */}
        {/*         更多 */}
        {/*       </span> */}
        {/*     )} */}
        {/*     <span className={`${styles.moreIcon} icon-home_gd`}/> */}
        {/*   </span> */}
        {/* </div> */}

        <div
          className={styles.announcement}
          style={{ height: this.props.height ? 'auto' : 216 }}
        >
          <div className={styles.header}>
            <span className={styles.title}>
              <span className={`${styles.icon} icon-home_gg`}/>
              公告
            </span>
            <span
              className={styles.moreText}
              onClick={() => this.handleGoToNotice()}
            >
              更多
              <span className={`${styles.moreIcon} icon-home_gd`}/>
            </span>
          </div>
          <div
            className={styles.notice}
            onClick={() => this.handleGoToNotice(notice.id)}
          >
            <div className={styles.firstTitle}>
              <span className={styles.text}>
                {notice.title}
              </span>
              <span className={styles.time}>
                {notice.createdAt ? moment(notice.createdAt)
                  .format('YYYY-MM-DD HH:mm') : ''}
              </span>
            </div>
            <div className={styles.firstContent}>
              {notice.introduction}
            </div>
            {notice.title && (
              <span
                className={styles.detail}
              >
                公告详情
                <span className={`${styles.moreIcon} icon-home_gd`}/>
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}
