import { Modal, Tabs } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type { Menu } from './interface';
import type ShortCutStore from './store';

interface Props {
  store: ShortCutStore;
}

const listPanel = (list: Menu[], type: string, parentId?: number, tabIndex?: number, callback?: (item: Menu, parentId?: number, index?: number) => void) => {
  return (
    <div className={styles.shortcutList}>
      {list.map((item) => (
        <div
          className={styles.item}
          key={item.id + type}
          onClick={!type && callback.bind(this, item)} // 快捷方式跳转对应iframe页面
        >
          <div className={styles.iconWrapper}>
            <span
              className={`${styles.icon} icon-${item.icon}`}
              style={{ color: type === 'ADD' ? '#6D6E78' : '#2387FA' }}
            />
            {type && (
              <span
                className={`${styles.operationIcon} icon-${type === 'ADD' ? 'home_tj' : 'home_sj'}`}
                onClick={callback.bind(this, item, parentId, tabIndex)}
              />
            )}
            {!type && item.num > 0 && (
              <span
                className={styles.numIcon}
              >
                {item.num}
              </span>
            )}
          </div>
          <span className={styles.name}>
            {item.name}
          </span>
        </div>
      ))}
    </div>
  );
};

@observer
export class ShortCut extends React.Component<Partial<Props>> {
  componentDidMount() {
    this.props.store.getCurrentMenu();
  }

  render(): JSX.Element {
    const { menu, isShowEdit, currentEditMenu, menuGroup, toggleShortcutEdit, handleShortAdd, handleShortDelete, handleShortCutSave, handleShortCutOpenTab } = this.props.store;

    const items = menuGroup.map((item, index) => ({
      label: item.name,
      key: String(item.id),
      children: listPanel(item.menus, 'ADD', item.id, index, handleShortAdd),
    }));
    return (
      <div
        className={styles.shortcut}
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <span className={styles.title}>
              快捷入口
            </span>
            <span
              className={`${styles.icon} icon-home_bj`}
              onClick={toggleShortcutEdit.bind(this, true)}
            />
          </div>
          {listPanel(menu, null, null, null, handleShortCutOpenTab)}
        </div>
        {isShowEdit && (
          <Modal
            centered
            closable={false}
            onCancel={toggleShortcutEdit.bind(this, false)}
            onOk={handleShortCutSave}
            open
            title="快捷入口"
            width={800}
            wrapClassName={styles.shortCurtModalWrapper}
          >
            <div className={styles.currentChoose}>
              {listPanel(currentEditMenu, 'DELETE', null, null, handleShortDelete)}
            </div>
            <div className={styles.reset}>
              <Tabs items={items}/>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
