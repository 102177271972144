import * as echarts from 'echarts';

const blueAreaStyle = {
  normal: {
    // 渐变填充色（线条下半部分）
    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: '#1978FF33', // 渐变色终点
      },
      {
        offset: 1,
        color: '#1978FF00', // 渐变色起点（由下到上）
      },
    ]),
  },
};

const baseOptions = {
  color: ['#1978FF'],
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'line',
      label: { width: 10 },
    },
  },
  xAxis:
    {
      data: [],
      boundaryGap: false, // 防止统计图左侧和纵轴有间隙
      axisTick: {
        show: true,
        lineStyle: { color: '#c5c3cb' },
      },
    },
  yAxis: [
    {
      splitLine: {
        show: true,
        lineStyle: {
          color: '#333',
          opacity: 0.05,
        },
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#333',
          opacity: 0.1,
        },
      },
      axisTick: {
        show: true,
        lineStyle: { color: '#c5c3cb' },
      },
    },
  ],
  grid: { // 图表的位置
    left: 23,
    top: 34,
    right: 22,
    bottom: 10,
    containLabel: true, // false是依据坐标轴来对齐的,true是依据坐标轴上面的文字边界来对齐
  },
  series: {
    name: '',
    type: 'line',
    showSymbol: false,
    smooth: false,
    data: [],
    areaStyle: blueAreaStyle,
  },
};
export { baseOptions };
