import * as echarts from 'echarts';

const originAreaStyle = {
  normal: {
    // 渐变填充色（线条下半部分）
    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: '#FF670D',
      },
      {
        offset: 1,
        color: '#FF670D00',
      },
    ]),
  },
};

const blueAreaStyle = {
  normal: {
    // 渐变填充色（线条下半部分）
    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: '#1978FF33', // 渐变色终点
      },
      {
        offset: 1,
        color: '#1978FF00', // 渐变色起点（由下到上）
      },
    ]),
  },
};

const LineBaseOptions = {
  color: ['#1978FF'],
  legend: {
    show: true,
    type: 'plain',
    right: 0,
    top: 0,
    icon: 'roundRect',
    itemHeight: 3,
    itemWidth: 14,
    data: [],
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'line',
      label: { width: 10 },
    },
  },
  xAxis:
    {
      data: [],
      boundaryGap: false, // 防止统计图左侧和纵轴有间隙
      axisTick: {
        show: true,
        lineStyle: { color: '#c5c3cb' },
      },
    },
  yAxis: [
    {
      splitLine: {
        show: true,
        lineStyle: {
          color: '#333',
          opacity: 0.05,
        },
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#333',
          opacity: 0.1,
        },
      },
      axisTick: {
        show: true,
        lineStyle: { color: '#c5c3cb' },
      },
    },
  ],
  grid: { // 图表的位置
    left: 23,
    top: 40,
    right: 40,
    bottom: 10,
    containLabel: true, // false是依据坐标轴来对齐的,true是依据坐标轴上面的文字边界来对齐
  },
  series: {
    name: '依链销量',
    type: 'line',
    showSymbol: false,
    smooth: true,
    data: [],
    areaStyle: blueAreaStyle,
  },
};

const doubleLineOptions = {
  ...LineBaseOptions,
  color: [
    '#FF670D',
    '#1978FF',
  ],
  legend: {
    ...LineBaseOptions.legend,
    data: [
      '开单销量',
      '依链销量',
    ],
  },
  yAxis: [
    ...LineBaseOptions.yAxis,
    { show: false },
  ],
  series: [
    {
      ...LineBaseOptions.series,
      name: '开单销量',
      yAxisIndex: 1,
      areaStyle: originAreaStyle,
    },
    LineBaseOptions.series,
  ],
};

const BarBaseOptions = {
  ...LineBaseOptions,
  tooltip: { show: false },
  xAxis: [
    {
      ...LineBaseOptions.xAxis,
      data: [
        '看款',
        '选款',
        '下图上架',
      ],
    },
  ],
  yAxis: [{ show: false }],
  series: {
    barWidth: 80,
    ...LineBaseOptions.series,
    type: 'bar',
    label: {
      textAlign: 'center',
      show: true,
      position: 'top',
      formatter: (params) => {
        const { data } = params;
        return `    {value|${data.value}家}\n {label|环比：}{percentage|${data.ringRatio}}`;
      },
      rich: {
        value: {
          color: '#2B2E3E',
          fontWeight: 500,
          fontSize: 16,
          lineHeight: 24,
        },
        label: {
          color: '#2B2E3E',
          fontSize: 12,
        },
        percentage: {
          fontSize: 12,
          color: '#FA6F6D',
        },
      },
    },
    data: [],
  },
  
};

export { LineBaseOptions, doubleLineOptions, BarBaseOptions };
