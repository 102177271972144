import { request } from 'egenie-common';
import React from 'react';
import cart from '../../assets/images/cart.png';
import { api } from '../../utils';
import { LayoutGuide, LayoutStore } from '../../utils/layout';
import styles from '../index.less';
import Dashboard from './dashboard/index';

const layoutStore = new LayoutStore({
  project: {
    name: '',
    value: 'egenie-erp-home',
  },
});
layoutStore.handleInit();
interface IState {
  isXuanKuan: boolean;
}

interface Opera {
  id: string | number;
  name: string;
  callback?: () => void;
}

// 导航 示例
export default class Index extends React.Component {
  public state: IState = { isXuanKuan: false };

  componentDidMount(): void {
    request({ url: api.user }).then((res: { tenantType?; status: string; }) => {
      const isXuanKuan = res.tenantType.includes('100015');
      this.setState({ isXuanKuan });
    });
  }

  public userInfoLeft = (): JSX.Element => {
    const { isXuanKuan } = this.state;
    return isXuanKuan && (
      <div>
        <span
          className={styles.shoppingCart}
          onClick={() => top.egenie.openTab('/egenie-ts-vogue/shoppingCart/index', 'shoppingCart', '进货车')}
        >
          <img
            src={cart}
            style={{
              width: 17,
              height: 17,
              marginBottom: 2,
              marginLeft: 0,
            }}
          />
          <span className={styles.cartText}>
            进货车
          </span>
          <span className={styles.splitIcon}>
            |
          </span>
        </span>
      </div>
    );
  };

  public userInfoRight = (): Opera[] => {
    return [
      {
        id: 'export',
        name: '导入导出任务中心',
        callback: () => top.egenie.openTab('/egenie-ts-baseinfo/exportList/index', 'export_task_center', '导入导出任务中心', 'zc_pfs'),
      },
    ];
  };

  public getLogoImg = (): JSX.Element => {
    return (
      <img
        onDragStart={(e) => {
          e.preventDefault();
        }}
        src={`${api.oss}/images/erpLogo.png`}
        style={{ width: '100%' }}
      />
    );
  };

  render(): JSX.Element {
    return (
      <div>
        <LayoutGuide
          defaultDashboard={(<Dashboard/>)}
          haveDashboard={false}
          logoImg={this.getLogoImg()}
          store={layoutStore}
          userInfoLeft={this.userInfoLeft()}
          userInfoRight={this.userInfoRight()}
        />
      </div>
    );
  }
}
