import { Popover } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { api } from '../../../../../utils/api';
import type { HeaderStatistics } from '../../interface';
import styles from './index.less';

interface Props {
  totalStatic: HeaderStatistics[];
}

const Header = observer((props: Props) => {
  const { totalStatic } = props;
   
  return (
    <div className={styles.header}>
      <img
        className={styles.statisticsBg}
        src={`${api.oss}/images/statisticsBg.png`}
      />
      <div className={styles.content}>
        <div className={styles.statisticsTitle}>
          <img
            className={styles.statisticsIcon}
            src={`${api.oss}/images/statisticsIcon.png`}
          />
          7日数据总览
        </div>
        <div className={styles.statisticsContent}>
          {totalStatic.map((item) => {
            return (
              <div
                className={styles.staticItem}
                key={item.key}
              >
                <div className={styles.number}>
                  {item.number}
                  <span className={styles.unit}>
                    {item.unit}
                  </span>
                </div>
                {item.hasTips ? (
                  <Popover
                    content={(
                      <div>
                        所有客户选款量总和
                        <div>
                          选款量=零售商1选款量+零售商2选款量+…+零售商n选款量
                        </div>
                      </div>
                    )}
                  >
                    <div
                      className={styles.title}
                    >
                      {item.title}
                      {item.hasTips && <span className={`${styles.tipsIcon} icon-note_zs`}/>}
                    </div>
                  </Popover>
                ) : (
                  <div
                    className={styles.title}
                  >
                    {item.title}
                    {item.hasTips && <span className={`${styles.tipsIcon} icon-note_zs`}/>}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export { Header };
