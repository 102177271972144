import { Input, Button, Form, message } from 'antd';
import { request, phoneReg } from 'egenie-common';
import React from 'react';
import { api } from '../../../utils';
import styles from './index.less';

message.config({ top: 56 });

export class ContactService extends React.Component {
  public form = null;

  public handleCopy = (text: string): void => {
    const ele: Partial<HTMLInputElement> = document.getElementById('copyText');
    ele.value = text;

    ele.select();
    document.execCommand('copy');
    message.success('复制成功');
  };

  public handleSubmit = async(values): Promise<void> => {
    const { feedBackphone, feedBacktext } = values;
    await request({
      method: 'post',
      url: api.commitFeedback,
      data: {
        content: feedBacktext,
        mobile: feedBackphone,
      },
    });
    message.success('反馈成功');
    this.form && this.form.setFieldsValue({
      feedBackphone: '',
      feedBacktext: '',
    });
  };

  render(): JSX.Element {
    return (
      <div className={styles.contactService}>
        <div className={styles.header}>
          <div className={styles.title}>
            联系客服
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.service}>
            <div className={styles.avatar}>
              <img src={`${api.oss}/images/service.png`}/>
              <div className={styles.nickName}>
                <span className={styles.serviceName}>
                  精灵客服(客服)
                </span>
                <span className={styles.onLine}>
                  在线
                </span>
              </div>
            </div>
            <div className={styles.contactWay}>
              <span className={styles.label}>
                电话:
              </span>
              <span>
                028-60116498
              </span>
            </div>
            <div className={styles.contactWay}>
              <span className={styles.label}>
                QQ:
              </span>
              <span className={styles.qq}>
                2893420754
              </span>
              <span
                className={styles.copy}
                onClick={this.handleCopy.bind(this, '2893420754')}
              >
                复制
              </span>
              <input
                id="copyText"
                style={{ opacity: 0 }}
              />
            </div>
          </div>
          <div className={styles.feedBack}>
            <div className={styles.header}>
              <div className={styles.title}>
                建议反馈
              </div>
            </div>
            <Form
              onFinish={this.handleSubmit}
              ref={(form) => {
                this.form = form;
              }}
              requiredMark={false}
            >
              <Form.Item
                name="feedBacktext"
                rules={[
                  {
                    required: true,
                    message: '请输入您的问题',
                  },
                ]}
              >
                <Input.TextArea
                  className={styles.text}
                  placeholder="请输入您的问题"
                  rows={3}

                />
              </Form.Item>
              <Form.Item
                label="联系电话"
                labelCol={{ span: 24 }}
                name="feedBackphone"
                rules={[
                  {
                    required: true,
                    message: '请输入手机号',
                  },
                  {
                    pattern: phoneReg,
                    message: '请输入正确手机号',
                  },
                ]}
              >
                <Input
                  maxLength={11}
                  placeholder="请输入11位有效手机号"
                  type="phone"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className={styles.submit}
                  htmlType="submit"
                  type="primary"
                >
                  提交
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
