import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable } from 'mobx';
import { api } from '../../../utils';
import ShortCutStore from '../../components/shortcut/store';
import type { HeadOverview, HeaderStatistics } from './interface';

class DashboardStore {
  @observable public totalStatic: HeaderStatistics[] = []; // 头部统计数据

  @observable public shortCutStore = new ShortCutStore({ homePageType: 2 }); // 快捷入口

  // 近七日数据总览
  @action public findRecentlyDataView = async(): Promise<void> => {
    const res: BaseData<HeadOverview> = await request({ url: `${api.findRecentlyDataView}` });
    const { newCustomerNum, putAwayDownloadGoodsNum, dynamicGoodsNum, selectedGoodsNum, mallSaleOrderPrice, mallSaleOrderSkuNum } = res.data;
    this.totalStatic = [
      {
        title: '新增客户数',
        key: 'newCustomerNum',
        number: newCustomerNum,
      },
      {
        title: '上款款数',
        key: 'putAwayDownloadGoodsNum',
        number: putAwayDownloadGoodsNum,
      },
      {
        title: '推款款数',
        key: 'dynamicGoodsNum',
        number: dynamicGoodsNum,
      },
      {
        title: '选款量',
        key: 'selectedGoodsNum',
        number: selectedGoodsNum,
        hasTips: true,
      },
      {
        title: '选款销量',
        key: 'mallSaleOrderSkuNum',
        number: mallSaleOrderSkuNum,
      },
      {
        title: '选款销售额',
        key: 'mallSaleOrderPrice',
        number: mallSaleOrderPrice,
        unit: '元',
      },
    ];
  };
}
export default DashboardStore;
