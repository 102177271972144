import ReactECharts from 'echarts-for-react';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { observer } from 'mobx-react';
import React from 'react';
import { api } from '../../../utils';
import { doubleLineOptions, LineBaseOptions, BarBaseOptions } from './baseOptions';
import styles from './index.less';
import type { StatisticsData } from './interface';

const tabsData = [
  {
    name: '7日销量',
    key: 'SALE',
  },
  {
    name: '7日退货',
    key: 'RETURN',
  },
  {
    name: '7日依链选款',
    key: 'SELECTED',
  },
];

@observer
export class SevenDaysStatics extends React.Component<{ isSCL?: boolean; }> {
  public state = {
    tab: 'SALE',
    xAxisData: [],
    yAxisData: [],
    yAxisDataPosSku: [], // 7日销量-pos销售sku数量
  };

  componentDidMount(): void {
    this.queryTopSale(this.state.tab);
  }

  public onTabChange = (tab: string): void => {
    this.setState({ tab });
    this.queryTopSale(tab);
  };

  public queryTopSale = async(tab: string): Promise<void> => {
    const res: BaseData<StatisticsData> = await request({ url: `${api.findReportForm}?queryType=${tab}` });
    if (tab === 'SALE') { // 7日销售
      const xAxisData = res.data.saleVoList.map((item) => item.date);
      const yAxisData = res.data.saleVoList.map((item) => item.mallSaleOrderSkuNum);
      const yAxisDataPosSku = res.data.saleVoList.map((item) => item.posSaleOrderSkuNum);
      this.setState({
        xAxisData,
        yAxisData,
        yAxisDataPosSku,
      });
    } else if (tab === 'RETURN') { // 7日退货
      const xAxisData = res.data.returnVoList.map((item) => item.date);
      const yAxisData = res.data.returnVoList.map((item) => item.posReturnOrderTenantNum);
      this.setState({
        xAxisData,
        yAxisData,
      });
    } else { // 7日选款：柱状图
      const { browsedDetailVo, selectedDetailVo, downloadPutAwayDetailVo } = res.data.selectedVo;
      this.setState({
        yAxisData: [
          {
            value: browsedDetailVo.tenantNum || 0,
            ringRatio: browsedDetailVo.ringRatio || 0,
            itemStyle: { color: '#1B79FF' },

          },
          {
            value: selectedDetailVo.tenantNum || 0,
            ringRatio: selectedDetailVo.ringRatio || 0,
            itemStyle: { color: '#02C190' },
          },
          {
            value: downloadPutAwayDetailVo.tenantNum || 0,
            ringRatio: downloadPutAwayDetailVo.ringRatio || 0,
            itemStyle: { color: ' #FFD44C' },
          },
        ],
      });
    }
  };

  render(): JSX.Element {
    const { tab, xAxisData, yAxisData, yAxisDataPosSku } = this.state;
    const saleOption = {
      ...doubleLineOptions,
      xAxis: {
        ...doubleLineOptions.xAxis,
        data: xAxisData,
      },
      series: [
        {
          ...doubleLineOptions.series[0],
          data: yAxisDataPosSku,
        },
        {
          ...doubleLineOptions.series[1],
          data: yAxisData,
        },
      ],
    };

    const returnOption = {
      ...LineBaseOptions,
      legend: {
        ...LineBaseOptions.legend,
        data: ['退货数量'],
      },
      xAxis: {
        ...LineBaseOptions.xAxis,
        data: xAxisData,
      },
      series: {
        ...LineBaseOptions.series,
        data: yAxisData,
        name: '退货数量',
      },
    };
    const selectOption = {
      ...BarBaseOptions,
      series: {
        ...BarBaseOptions.series,
        data: yAxisData,
      },
    };
    const tabList = this.props.isSCL ? [
      tabsData[0],
      tabsData[2],
    ] : tabsData;
    return (
      <div className={styles.sevenDaysStatics}>
        <div className={styles.tabWrapper}>
          {tabList.map((item) => (
            <div
              className={tab === item.key ? `${styles.tabItem} ${styles.activeTabItem}` : styles.tabItem}
              key={item.key}
              onClick={() => this.onTabChange(item.key)}
            >
              <span>
                {item.name}
              </span>
              <span className={tab === item.key ? styles.active : undefined}/>
            </div>
          ))}
        </div>
        <div className={styles.chartsPart}>
          <ReactECharts
            lazyUpdate
            notMerge
            option={tab === 'SALE' ? saleOption : tab === 'RETURN' ? returnOption : selectOption}
            style={{ height: 200 }}
            theme="theme_name"
          />
        </div>
      </div>
    );
  }
}

