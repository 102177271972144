import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { api } from '../../../utils/api';
import styles from './index.less';
import type { Notice } from './interface';

interface Props {
  platformType: number;
}

@observer
export class SystemUpdate extends React.Component<Props> {
  public state = { list: []};

  async componentDidMount(): Promise<void> {
    const res: BaseData<Notice[]> = await request({
      url: api.findNoticeByParam,
      method: 'POST',
      data: {
        noticeType: 2, // 1:公告 2:系统更新
        number: 6,
        platformType: this.props.platformType, // 1:erp 2:pos 3:零售商app 4:供应商app 5：网仓 6:scl
      },
    });
    this.setState({ list: res.data });
  }

  public handleGoToNoticeList = (id?: number): void => {
    top.egenie.openTab(`/egenie-erp-home/announcement?type=2&platformType=${this.props.platformType}&id=${id ? id : ''}`, 'systemUpdate', '系统更新');
  };

  render(): JSX.Element {
    return (
      <div className={styles.systemUpdate}>
        <div className={styles.header}>
          <div className={styles.title}>
            系统更新
          </div>
          <span
            className={styles.moreText}
            onClick={() => this.handleGoToNoticeList()}
          >
            更多
            <span className={`${styles.moreIcon} icon-home_gd`}/>
          </span>
        </div>
        <div className={styles.content}>
          {this.state.list.map((item: Notice, index) => {
            return (
              <div
                className={styles.infoWrapper}
                key={item.id}
                onClick={() => this.handleGoToNoticeList(item.id)}
              >
                {index === 0 && (
                  <img
                    className={styles.newIcon}
                    src={`${api.oss}/images/news.png`}
                  />
                )}
                <span
                  className={styles.text}
                  style={{ marginLeft: index === 0 ? 0 : 28 }}
                >
                  {moment(item.createdAt).format('YYYY-MM-DD')}
                  {' '}
                  {item.title}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
