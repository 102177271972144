
import { } from 'antd';
import { Provider, observer } from 'mobx-react';
import React from 'react';
import { ShortCut, Notice, RelatedProduct, ContactService, TopStatics, SevenDaysStatics } from '../../components/index';
import styles from './index.less';
import DashboardStore from './store';
import { Header } from './widget/header/index';

const dashboardStore = new DashboardStore();
export class Dashboard extends React.Component {
  componentDidMount(): void {
    dashboardStore.findRecentlyDataView();
  }

  render(): JSX.Element {
    const { totalStatic, shortCutStore } = dashboardStore;
    return (
      <div className={styles.dashboard}>
        <Provider dashboardStore={dashboardStore}>
          <Header totalStatic={totalStatic}/>
          <div className={styles.panel}>
            <ShortCut store={shortCutStore}/>
            <Notice platformType={6}/>
          </div>
          <div className={styles.panel}>
            <SevenDaysStatics isSCL/>
            <TopStatics isSCL/>
          </div>
          <div className={styles.panel}>
            <RelatedProduct platformType={6}/>
            <ContactService/>
          </div>
        </Provider>
      </div>
    );
  }
}

export default observer(Dashboard);
